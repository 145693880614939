
// @ is an alias to /src
import LogoHeader from "@/components/organisms/LogoHeader.vue";
import Footer from "@/components/organisms/Footer.vue";
import { defineComponent, onMounted, ref } from "vue";
import { AccountRepository, RepositoryFactory } from "@/lib/https";

export default defineComponent({
  name: "Payment",
  components: {
    LogoHeader,
    Footer,
  },
  props: {
    isLoggedIn: { type: Boolean, default: false },
  },
  setup() {
    const user = ref();
    const { getFullProfile } =
      RepositoryFactory.getRepository<AccountRepository>(AccountRepository);
    onMounted(async () => {
      const profilePayload = await getFullProfile();
      user.value = profilePayload;
    });

    return { user };
  },
});
