import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-2bc86d89"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "payment" }
const _hoisted_2 = { class: "home" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_LogoHeader = _resolveComponent("LogoHeader")!
  const _component_router_view = _resolveComponent("router-view")!
  const _component_Footer = _resolveComponent("Footer")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_LogoHeader, {
        "is-logged-in": _ctx.isLoggedIn,
        user: _ctx.user
      }, null, 8, ["is-logged-in", "user"]),
      _createVNode(_component_router_view, {
        role: _ctx.user?.role
      }, null, 8, ["role"]),
      _createVNode(_component_Footer, { "white-footer": true })
    ])
  ]))
}